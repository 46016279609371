<script setup lang="ts">
import type { SelectOption } from '~/components/v/Select.vue'

const { chainConfig } = useConnector()

const { currentChainId } = useConnector()

function getConfig(id: string) {
  return chainConfig.value.find(i => i.id === Number(id))
}

const chainConfigSelectList = computed(() => {
  return chainConfig.value.map(i => ({
    value: `${i.id}`,
    label: i.name,
  }))
})

const currentChain = ref(chainConfigSelectList.value.find(i => i.value === `${currentChainId.value}`))

function onChangeChainId(chain: SelectOption) {
  currentChainId.value = Number(chain.value)
}
</script>

<template>
  <v-select
    v-model="currentChain" plain
    :options="chainConfigSelectList"
    popover-class="right-0 left-auto w-1.9" @change="onChangeChainId"
  >
    <template #option="{ item, selected }">
      <div class="h-0.24 flex items-center">
        <VIcon :url="getConfig(item.value)?.icon" class="mr-0.08 h-0.16 w-0.16" />
        <span class="mr-0.08" :class="{ 'text-primary': selected }">
          {{ getConfig(item.value).name }}
        </span>
      </div>
    </template>
    <template #result="{ value }">
      <div class="h-0.16! w-0.16!">
        <VIcon :url="getConfig(value.value)?.icon" class="h-0.16! w-0.16!" />
      </div>
    </template>
  </v-select>
</template>

<style scoped>

</style>
