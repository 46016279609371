export const useAmm = createGlobalState(() => {
  // const { run: _getPoolList, isLoading: isLoadingList } = useHttp(vesselApi.amm.getPoolList)
  const { run: _getPoolSummaryList, isLoading: isLoadingDetailList } = useHttp(vesselApiServer.getPoolSummaryList)
  const { run: _getPoolOverviewList, isLoading: isLoadingOverviewList } = useHttp(vesselApiServer.getPoolList)
  const { run: getPosition, isLoading: isLoadingUserPosition } = useHttp(vesselApiServer.getAmmPosition)
  // const poolList = ref<PoolItem[]>([])
  const poolSummaryList = ref<PoolSummaryItem[]>([])
  const poolOverviewList = ref<PoolItem[]>([])
  const selectPoolRow = ref<AmmPositionItem>()
  const userPoolList = ref<AmmPositionItem[]>([])
  const { isSigned } = useKey()

  const selectPool = computed(() => userPoolList.value.find(i => i.poolId === selectPoolRow.value?.poolId && i.tickL === selectPoolRow.value.tickL && i.tickR === selectPoolRow.value.tickR))
  const poolList = computed(() => poolOverviewList.value.map(i => (
    {
      ...i,
      ...poolSummaryList.value.find(j => j.poolId === i.poolId),
      symbolName: getSymbolItem(i.symbolId)?.symbolName,
    }
  )))
  const isLoadingPool = computed(() => isLoadingDetailList.value || isLoadingOverviewList.value)
  function getPoolOverviewList() {
    _getPoolOverviewList().then((res) => {
      poolOverviewList.value = res.data
    })
  }
  function getPoolSummaryList() {
    _getPoolSummaryList().then((res) => {
      poolSummaryList.value = res.data.pools
    })
  }
  function updatePoolSummary() {
    if (!poolSummaryList.value.length) {
      getPoolSummaryList()
    }
  }
  function updatePoolOverviewList() {
    if (!poolOverviewList.value.length) {
      getPoolOverviewList()
    }
  }

  function initPoolList() {
    updatePoolSummary()
    updatePoolOverviewList()
  }

  function getUserPosition() {
    if (isSigned.value) {
      getPosition().then((res) => {
        userPoolList.value = res.data
      })
    }
  }

  return {
    poolList,
    isLoadingPool,
    initPoolList,
    getPoolSummaryList,
    getPoolOverviewList,
    poolSummaryList,
    poolOverviewList,
    isLoadingOverviewList,
    updatePoolSummary,
    updatePoolOverviewList,
    selectPool,
    selectPoolRow,
    isLoadingDetailList,
    getUserPosition,
    userPoolList,
    isLoadingUserPosition,
  }
})
