import { useAccount } from '@wagmi/vue'
import { formatUnits } from 'viem'
import type { StatsUserInfo, UserPreference } from '~/service/modules/stats'

interface InvitationCode {
  isValid: boolean
  code: string
  isNewCode: boolean
  boundAddress: string
}

const defaultInvitation = {
  isValid: false,
  code: '',
  isNewCode: false,
  boundAddress: '',
}

export type AssetItemWithClaimable = UserAsset & { claimable?: number }

export const useUser = createGlobalState(() => {
  const userInfo = ref<UserInfo>()
  const userStatsInfo = ref<StatsUserInfo>()
  const { address, isConnected, chainId: accountChainId } = useAccount()
  const initAddress = ref('')
  const { userAssetList } = useData()
  const assetListWithClaimable = ref<AssetItemWithClaimable[]>([])
  const preference = ref<UserPreference>()
  const isRegistering = ref(false)

  const { run: getAssetList, isLoading: isLoadingAsset } = useHttp(vesselApiServer.getUserAssets)

  // invitation
  const invitation = useStorage<InvitationCode>('invitationCode', defaultInvitation)

  function clearInvitation() {
    invitation.value = defaultInvitation
  }

  function initStatsUserInfo() {
    vesselApi.stats.getStatsUserInfo().then((res) => {
      userStatsInfo.value = res.data
    })
  }

  function initUserInfo() {
    getUserAsset()
    initPreference()
    return vesselApiServer.getUserInfo().then((res) => {
      userInfo.value = res.data
      return res.data
    })
  }

  function initPreference() {
    vesselApi.stats.getPreference().then((res) => {
      preference.value = res.data
    }).catch(console.log)
  }

  async function updatePreference() {
    await vesselApi.stats.updatePreference(preference.value)
  }

  function clearUserInfo() {
    preference.value = null
    userInfo.value = null
  }

  async function getClaimableFromAssetName(assetName: string) {
    const assetItem = getAssetItem(assetName)
    if (!assetItem?.assetId) {
      return 0
    }
    const data = await getClaimableFromChain(+assetItem.assetId)
    return +formatUnits(data, +assetItem.onChainDecimal)
  }

  function getUserAsset() {
    getAssetList().then(async (res) => {
      userAssetList.value = res.data
    })
  }
  async function getUserAssetWithClaim() {
    if (assetListWithClaimable.value.length) {
      return
    }
    for (const item in userAssetList.value) {
      assetListWithClaimable.value[item] = {
        ...userAssetList.value[item],
        claimable: await getClaimableFromAssetName(userAssetList.value[item].assetName),
      }
    }
  }

  return {
    userInfo,
    address,
    isConnected,
    invitation,
    clearInvitation,
    initUserInfo,
    clearUserInfo,
    getUserAsset,
    userStatsInfo,
    isLoadingAsset,
    preference,
    updatePreference,
    assetListWithClaimable,
    initStatsUserInfo,
    initAddress,
    getUserAssetWithClaim,
    isRegistering,
    accountChainId,
  }
})
