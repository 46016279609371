export const useData = createGlobalState(() => {
  const userAssetList = ref<UserAsset[]>([])
  const assetPriceList = ref<AssetPriceItem[]>([])
  const symbolPrice = ref <Record<string, LastTradeItem>>({})

  return {
    userAssetList,
    assetPriceList,
    symbolPrice,
  }
})
