const ommApi = {
  getPoolOverviewList: (params: PoolListReq) => http.get<A<PoolListOverviewRes>>('/gateway/public/pool', { params }),
  // getPoolList: (params: PoolListReq) => http.get<A<PoolListRes>>('/amm/detailedPools', { params }),
  getPoolDetailList: (params: PoolListReq) => http.get<A<PoolDetailListRes>>('/gateway/public/pool/detail'),
  addPool: (data: AddPoolReq, options: any) => http.post('/amm/add', data, options),
  getUserPosition: () => http.get('/amm/user/positions'),
  getPoolHistory: (params: any) => http.get<A<PoolHistoryRes>>('/gateway/user/history/ammUserAction', { params }),
  collectFee: (data: AddPoolReq, options: any) => http.post('/amm/collectFee', data, options),
  removePool: (data: AddPoolReq, options: any) => http.post('/amm/remove', data, options),
  getPoolLiquidity: (poolId: number) => http.get<LiquidityRes>(`/gateway/public/pool/liquidity`, { params: { poolId } }),
  getPoolPerformance: (poolId: number) => http.get<PerformanceRes>(`/gateway/public/pool/performance`, { params: { poolId } }),
}

export default ommApi

interface PoolDetailListRes {
  pools: PoolDetailItem[]
}

export interface PoolDetailItem {
  maxPositionSize: string
  maxTick: string
  minTick: string
  operatorFeeRate: string
  poolId: string
  symbol: string
  tickSpacing: string
  priceRange: string
}

interface PoolListReq {
  symbol?: string
  poolId?: string
}

export interface PoolItem {
  poolId: string
  symbol: string
  minTick: string
  maxTick: string
  tickSpacing: string
  maxPositionSize: string
  operatorFeePercentage: string
  baseTVL: string
  quoteTVL: string
  priceTickLeft: string
  priceTickLeftLiquidity: string
  priceTickLiquidity: string
}

export interface PoolBoost {
  boost: number
  startTime: string
  endTime: string
}
export interface PoolOverviewItem {
  apr: string
  baseTokenAddress: string
  baseTokenName: string
  baseTvl: string
  baseTvlValue: string
  feeValue24h: string
  poolId: string
  quoteTokenAddress: string
  quoteTokenName: string
  quoteTvl: string
  quoteTvlValue: string
  tradeVolume24h: string
  boost: PoolBoost
  tickSpacing: string
}

interface PoolHistoryRes {
  history: PoolHistoryItem[]
}

interface PoolListRes {
  pools: PoolItem[]
}

interface PoolListOverviewRes {
  pools: PoolOverviewItem[]
}

export interface AddPoolReq {
  poolId: number
  tickIndexL: number
  tickIndexR: number
  baseAssetAmount: number
  quoteAssetAmount: number
  nonce: number
  signature: string
}

export interface UserPositionItem {
  poolId: string
  tickL: string
  tickR: string
  baseAmount: string
  quoteAmount: string
  baseFeeAmount: string
  quoteFeeAmount: string
}

export interface PoolHistoryItem {
  baseAssetAmount?: string
  baseAssetName: string
  poolId?: string
  quoteAssetAmount?: string
  quoteAssetName: string
  tickL?: string
  tickR?: string
  timestamp?: string
  type: string
  id: string
}

export interface LiquidityItem {
  startTick: string
  endTick: string
  baseTokenAmount: string
}

interface LiquidityRes {
  ticks: LiquidityItem[]
}

export interface PerformanceItem {
  dt: string
  fee: string
  tvl: string
  volume: string
}

interface PerformanceRes {
  data: PerformanceItem[]
}
