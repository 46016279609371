<script setup lang="ts">
import { usePutOrder } from '../limit'

const { poolList, initPoolList } = useAmm()
const { currentSymbol } = useSymbol()
const tabOption = [
  { value: 'limit', label: 'Limit' },
  { value: 'market', label: 'Market' },
  // { value: 'stopLimit', label: 'Stop limit' },
]
const { current, type } = usePutOrder()

const currentPool = computed(() => {
  return poolList.value.find(i => i.symbolName === currentSymbol.value)
})

onMounted(() => {
  initPoolList()
})
</script>

<template>
  <div class="p-0.1">
    <div
      class="type-button relative mb-0.24 h-0.32 w-1.3 w-full overflow-hidden rd-0.08 bg-black3 text-center text-0.14 font-600"
      :class="{
        'show-buy': type === 'buy',
        'show-sell': type === 'sell',
      }"
    >
      <div class="absolute inset-0 z-1 flex cursor-pointer items-center">
        <div class="flex-1" @click="type = 'buy'">
          Buy
        </div>
        <div class="flex-1" @click="type = 'sell'">
          Sell
        </div>
      </div>
    </div>
    <v-tabs v-model="current" :options="tabOption" size="small" class="mb-0.16" />
    <trade-operation-limit v-if="current === 'limit'" />
    <trade-operation-market v-if="current === 'market'" />
    <div
      v-if="currentPool" class="mt-0.12 cursor-pointer text-0.12 text-grey3 font-500 font-poppins underline"
      @click="$router.push(`/pools/add?symbol=${currentSymbolItem.symbol}`)"
    >
      Become an LP and <span class="text-primary underline underline-primary">Earn {{ getRatio(+currentPool.apr) }} APR</span>
    </div>
  </div>
</template>

<style scoped>
.type-button {
  &.show-buy {
    &::before {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &.show-sell {
    &::after {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &::before {
    transition: all 0.1s ease-out;
    content: '';
    position: absolute;
    background: var(--vessel-color-green);
    width: calc(260rem / 400);
    padding-top: calc(64rem / 400);
    border-radius: calc(32rem / 400);
    top: 0;
    left: calc(-125rem / 400);
    /* transform: translate(calc(-130rem / 400), calc(32rem / 400)); */
    opacity: 0;
  }

  &::after {
    transition: all 0.1s ease-out;
    content: '';
    position: absolute;
    background: var(--vessel-color-red);
    width: calc(260rem / 400);
    padding-top: calc(64rem / 400);
    border-radius: calc(32rem / 400);
    top: calc(-32rem / 400);
    right: calc(-125rem / 400);;
    opacity: 0;
    /* transform: translate(calc(130rem / 400), calc(-32rem / 400)); */
  }
}
</style>
