export const useFaucet = createGlobalState(() => {
  const { openModal } = useModal()
  const mintedList = ref<MintedItem[]>([])
  const isNewUser = ref(false)

  const allMintList = computed(() => {
    return getAssetListCurrentChain()
  })

  function getFaucetInfo() {
    vesselApiServer.getMintedList().then((res) => {
      mintedList.value = res.data
      // isNewUser.value = res.data.isNewUser
      // if (res.data.isNewUser && location.pathname !== '/faucet' && !IS_MAINNET) {
      //   openModal('remindFaucet')
      // }
    }).catch(console.log)
  }
  return {
    mintedList,
    allMintList,
    isNewUser,
    getFaucetInfo,
  }
})
