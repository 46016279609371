import type { InternalAxiosRequestConfig } from 'axios'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import encHex from 'crypto-js/enc-hex'

// @ts-expect-error no type
import { buildPoseidon } from 'circomlibjs'
import secp256k1 from 'secp256k1'
import BN from 'bignumber.js'
import { hexToNumber, parseUnits } from 'viem'
import type { AddPoolReq } from '~/service/modules/amm'

export function getApiHeader(config: InternalAxiosRequestConfig): Record<string, string> {
  const { apiKey } = useKey()
  const { address } = useUser()
  if (apiKey.value && apiKey.value.secret) {
    let timestamp
    if (!config.headers['VESSEL-TIMESTAMP']) {
      timestamp = new Date().getTime()
    }
    else {
      timestamp = config.headers['VESSEL-TIMESTAMP']
    }
    const paramKeyList = config.params ? Object.keys(config.params).filter(i => config.params[i] !== undefined && config.params[i] !== null) : []
    const params = config.params && (paramKeyList.length) ? `?${paramKeyList.map(i => `${i}=${config.params[i]}`).join('&')}` : ''
    const data = config.data ? JSON.stringify(config.data) : ''
    const signature = Base64.stringify(hmacSHA256(
      `${timestamp}${config.method?.toUpperCase()}${config.baseURL}${config.url}${params}${data}`,
      encHex.parse(apiKey.value.secret.slice(2)),
    ))

    return {
      'VESSEL-TIMESTAMP': `${timestamp}`,
      'VESSEL-API-KEY': apiKey.value.key,
      'VESSEL-PASSPHRASE': apiKey.value.passphrase,
      'VESSEL-SIGNATURE': signature,
      'VESSEL-ADDRESS': address.value || '',
    }
  }

  return {}
}

export async function signOrder(
  data: Omit<PlaceOrderRequest, 'signature'>,
  signType: number = 4,
) {
  const isBuy = data.side === 'BUY'
  const symbolItem = getSymbolItem(data.symbolName)
  const assetCurrentChainItem = getAssetListCurrentChain()
  const baseAssetItem = assetCurrentChainItem.find(i => i.assetId === symbolItem.baseAssetId)
  const quoteAssetItem = assetCurrentChainItem.find(i => i.assetId === symbolItem.quoteAssetId)

  const { userInfo } = useUser()
  const { vesselKey } = useKey()
  console.log(userInfo)

  const { takerFee, makerFee } = getUserFee(data.symbolName)
  console.log(takerFee, makerFee)

  const poseidon = await buildPoseidon()

  const buyAssetId = BN(symbolItem[isBuy ? 'baseAssetId' : 'quoteAssetId']).toFixed()
  const sellAssetId = BN(symbolItem[isBuy ? 'quoteAssetId' : 'baseAssetId']).toFixed()
  const takerFeeRate = BN(takerFee).multipliedBy(BN('1e6')).toFixed()
  const makerFeeRate = BN(makerFee).multipliedBy(BN('1e6')).toFixed()
  const timestamp = BN(data.timestamp).toFixed()
  const buyAssetAmount = BN(data.size).multipliedBy(BN(isBuy ? 1 : data.price))
    .multipliedBy(`1e${isBuy ? baseAssetItem.onChainDecimal : quoteAssetItem.onChainDecimal}`).toFixed()
  const sellAssetAmount = BN(data.size).multipliedBy(BN(isBuy ? data.price : 1))
    .multipliedBy(`1e${isBuy ? quoteAssetItem.onChainDecimal : baseAssetItem.onChainDecimal}`).toFixed()
  const x = BN(buyAssetId)
    .plus(BN(sellAssetId).multipliedBy(BN(2).pow(BN(32))))
    .plus(BN(takerFeeRate).multipliedBy(BN(2).pow(BN(64))))
    .plus(BN(makerFeeRate).multipliedBy(BN(2).pow(BN(96))))
    .plus(BN(timestamp).multipliedBy(BN(2).pow(BN(128)))).toFixed()

  const y = BN(buyAssetAmount).plus(BN(sellAssetAmount).multipliedBy(BN(2).pow(BN(126)))).toFixed()

  const z = BN(data.timestamp).toFixed()

  const fHash = poseidon([signType, x])
  const xyHash = poseidon([fHash, y])
  const xyzHash = poseidon([xyHash, z])

  const pubKeyX = BigInt(`0x${vesselKey.value.publicKey.slice(2, 66)}`)
  const pubKeyY = BigInt(`0x${vesselKey.value.publicKey.slice(66)}`)

  const Fq = BigInt('21888242871839275222246405745257275088548364400416034343698204186575808495617')
  const u = poseidon([pubKeyX % Fq, pubKeyY % Fq])

  const hash = poseidon([xyzHash, u])
  const signHash = hexToByte(poseidon.F.toString(hash, 16).padStart(64, '0'))
  const sigObj = secp256k1.ecdsaSign(signHash, hexToByte(vesselKey.value.privateKey))
  console.log('buyAssetId', buyAssetId)
  console.log('sellAssetId', sellAssetId)
  console.log('takerFeeRate', takerFeeRate)
  console.log('makerFeeRate', makerFeeRate)
  console.log('timestamp', timestamp)
  console.log('buyAssetAmount', buyAssetAmount)
  console.log('sellAssetAmount', sellAssetAmount)
  console.log('x', x)
  console.log('y', y)
  console.log('z', z)
  console.log('pubX', pubKeyX)
  console.log('pubY', pubKeyY)
  console.log('u', poseidon.F.toString(u))
  console.log('signType', signType)
  console.log('signTypeXHash', poseidon.F.toString(fHash))
  console.log('xyHash', poseidon.F.toString(xyHash))
  console.log('xyzHash', poseidon.F.toString((xyzHash)))
  console.log('hash', poseidon.F.toString(hash), 'pk', vesselKey.value.privateKey)
  console.log('sig', byteToHex(sigObj.signature))
  return {
    signature: byteToHex(sigObj.signature),
    timestamp,
  }
}

export async function signPool(data: Omit<AddPoolReq, 'signature'> & { timestamp: number }, symbol: string, signType: number = 2) {
  const { vesselKey } = useKey()
  const currentSymbol = getSymbolItem(symbol)
  const assetCurrentChainItem = getAssetListCurrentChain()
  const baseAssetItem = assetCurrentChainItem.find(i => i.assetId === currentSymbol.baseAssetId)
  const quoteAssetItem = assetCurrentChainItem.find(i => i.assetId === currentSymbol.quoteAssetId)
  console.log(data)
  const x = BN(data.poolId)
    .plus(BN(data.tickIndexL).multipliedBy(BN(2).pow(BN(32))))
    .plus(BN(data.tickIndexR).multipliedBy(BN(2).pow(BN(96))))
    .plus(BN(data.timestamp).multipliedBy(BN(2).pow(BN(160))))
    // .plus(BN(data.addType).multipliedBy(BN(2).pow(BN(224))))
    .toFixed()

  const baseAmount = BN(data.baseAssetAmount).multipliedBy(`1e${baseAssetItem.onChainDecimal}`).toFixed()
  const quoteAmount = BN(data.quoteAssetAmount).multipliedBy(`1e${quoteAssetItem.onChainDecimal}`).toFixed()
  console.log(baseAmount, quoteAmount)

  const y = BN(baseAmount)
    .plus(BN(quoteAmount).multipliedBy(BN(2).pow(BN(126)))).toFixed()
  const z = BN(data.nonce).toFixed()
  console.log(x, y, z)

  const poseidon = await buildPoseidon()
  const txHash = poseidon([signType, x])
  const xyHash = poseidon([txHash, y])
  const hash = poseidon([xyHash, z])
  console.log(poseidon.F.toString(xyHash))
  console.log(poseidon.F.toString(hash))
  const signHash = hexToByte(poseidon.F.toString(hash, 16).padStart(64, '0'))
  const sigObj = secp256k1.ecdsaSign(signHash, hexToByte(vesselKey.value.privateKey))
  return byteToHex(sigObj.signature)
}

interface TransferData {
  assetId: string
  assetOnChainDecimal: string
  toAddress: string
  amount: string
  fee: string
  timestamp: string
}

export async function signTransfer(data: TransferData) {
  const { vesselKey } = useKey()

  console.log(BN((data.toAddress.toLowerCase() as `0x${string}`)).toFixed())

  const x = BN(data.assetId)
    .multipliedBy(BN(2).pow(BN(160)))
    .plus(BN((data.toAddress.toLowerCase() as `0x${string}`))).toFixed()

  const amount = parseUnits(data.amount, +data.assetOnChainDecimal).toString()
  const y = BN(amount).multipliedBy(BN(2).pow(BN(126))).plus(BN(data.fee)).toFixed()
  const z = BN(data.timestamp).toFixed()
  const h = BN(data.timestamp).toFixed()

  console.log('x', x)
  console.log('y', y)
  console.log('x', z)

  const poseidon = await buildPoseidon()
  const txHash = poseidon([0, x])
  const xyHash = poseidon([txHash, y])
  const xyzHash = poseidon([xyHash, z])
  const hash = poseidon([xyzHash, h])
  console.log('xyHash', poseidon.F.toString(xyHash))
  console.log('xyzHash', poseidon.F.toString(xyzHash))
  console.log('hash', poseidon.F.toString(hash))
  const signHash = hexToByte(poseidon.F.toString(hash, 16).padStart(64, '0'))
  const sigObj = secp256k1.ecdsaSign(signHash, hexToByte(vesselKey.value.privateKey))
  return byteToHex(sigObj.signature)
}

export async function signWithdraw(data: Omit<WithdrawReq, 'signature' | 'chainId'>) {
  const { vesselKey } = useKey()

  const assetItem = getAssetItem(data.assetId)

  const x = BN(data.timestamp)
    .multipliedBy(BN(2).pow(BN(32)))
    .plus(BN(data.assetId)).toFixed()

  const amount = parseUnits(data.amount, +assetItem.onChainDecimal).toString()
  const y = BN(amount)
    .multipliedBy(BN(2).pow(BN(126)))
    .plus(BN(data.feeAmount)).toFixed()
  const z = BN(data.nonce).toFixed()

  console.log('x', x)
  console.log('y', y)
  console.log('z', z)

  const poseidon = await buildPoseidon()
  const txHash = poseidon([1, x])
  const txyHash = poseidon([txHash, y])
  const hash = poseidon([txyHash, z])
  console.log('txHash', poseidon.F.toString(txHash))
  console.log('txyHash', poseidon.F.toString(txyHash))
  console.log('hash', poseidon.F.toString(hash))
  const signHash = hexToByte(poseidon.F.toString(hash, 16).padStart(64, '0'))
  const sigObj = secp256k1.ecdsaSign(signHash, hexToByte(vesselKey.value.privateKey))
  return byteToHex(sigObj.signature)
}
