import BN from 'bignumber.js'

export function getAssetItem(assetOrAssetId: string) {
  const { assetList } = useSymbol()
  const { currentChainId } = useConnector()
  const item = assetList.value.find(
    i => i.assetId === assetOrAssetId || i.assetName === assetOrAssetId || i.fullName === assetOrAssetId,
  )
  return !item
    ? undefined
    : {
        ...item,
        ...(item.networks.find(i => `${i.chainId}` === `${currentChainId.value}`) || item.networks[0]),
      }
}

export function getSymbolItem(symbolOrSymbolId: string) {
  const { symbolList } = useSymbol()
  const symbolItem = symbolList.value.find(i => i.symbolId === symbolOrSymbolId || i.symbolName === symbolOrSymbolId)
  if (!symbolItem) {
    return null
  }
  const baseAssetItem = getAssetItem(symbolItem.baseAssetId)
  const quoteAssetItem = getAssetItem(symbolItem.quoteAssetId)
  return {
    ...symbolItem,
    base: baseAssetItem.assetName,
    baseName: baseAssetItem.assetName,
    baseAssetName: baseAssetItem.assetName,
    quote: quoteAssetItem.assetName,
    quoteName: quoteAssetItem.assetName,
    quoteAssetName: quoteAssetItem.assetName,
  }
}

export function getSymbolItemByPoolId(poolId: string) {
  const { poolList } = useAmm()
  const symbol = poolList.value.find(i => i.poolId === poolId)?.symbolName
  return getSymbolItem(symbol)
}

export function getPrecisionFromSymbol(symbol: string) {
  const symbolItem = getSymbolItem(symbol)
  if (!symbolItem) {
    return {
      price: 2,
      total: 4,
      base: 2,
      value: 4,
      amount: 2,
    }
  }
  return {
    price: +symbolItem.priceDecimal,
    total: +symbolItem.priceDecimal + +symbolItem.baseDecimal,
    value: +symbolItem.priceDecimal + +symbolItem.baseDecimal,
    quote: +symbolItem.priceDecimal + +symbolItem.baseDecimal,
    amount: +symbolItem.baseDecimal,
    base: +symbolItem.baseDecimal,
  }
}

export function getUserFee(symbol: string, ...args: any[]) {
  const { userInfo } = useUser()
  const { feeList } = useSymbol()
  const symbolItem = getSymbolItem(symbol)
  if (!feeList.value.length || !symbol || !userInfo.value) {
    return {
      takerFee: 0,
      makerFee: 0,
    }
  }
  const feeItem = feeList.value.find(i => `${i.feeLevel}` === `${userInfo.value.feeLevel}`)
  console.log(userInfo, feeList.value)

  if (!symbolItem) {
    return {
      takerFee: +feeItem.takerFeeRate,
      makerFee: +feeItem.makerFeeRate,
    }
  }
  return {
    takerFee: +BN(feeItem.takerFeeRate).multipliedBy(symbolItem.takerFeeDiscount).dividedBy(BN(100)).toFixed(6),
    makerFee: +BN(feeItem.makerFeeRate).multipliedBy(symbolItem.makerFeeDiscount).dividedBy(BN(100)).toFixed(6),
  }
}

export function addSymbolSlash(symbol: string) {
  const symbolItem = getSymbolItem(symbol)
  return `${symbolItem?.base}/${symbolItem?.quote}` || ''
}

export function getAssetListCurrentChain() {
  const { assetList } = useSymbol()
  const { currentChainId } = useConnector()
  return assetList.value.map(i => ({
    ...i,
    ...(i.networks.find(j => `${j.chainId}` === `${currentChainId.value}`) || i.networks[0]),
    networks: undefined,
  }))
}
