<script setup lang="ts">
import type { Column } from '~/components/v/Table.vue'

const props = defineProps<{
  historyCurrentSymbol?: string
}>()
const { assetList } = useSymbol()
const { isSigned } = useKey()
const isOnlyCurrent = useStorage('isOnlyCurrentOpen', false)

const { run: getApiOrderHistory, isLoading } = useHttp(vesselApiServer.getInternalHistory)

const filter = ref({
  type: null,
  range: [dayjs().subtract(6, 'days').valueOf(), dayjs().valueOf()],
  assetName: null,
})

const noMore = ref(false)
const typeList = ['Send', 'Receive']
const assetFilterList = computed(() => assetList.value.map(i => ({ value: i.assetName, label: i.assetName })))
const historyList = ref<UserInternalHistory[]>([])

const tableColumns: Column[] = [
  {
    key: 'time',
    title: 'Time',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'type',
    title: 'Type',
    minWidth: 80,
    align: 'left',
  },
  {
    key: 'address',
    title: 'From/To',
    minWidth: 100,
    align: 'left',
  },
  {
    key: 'assetName',
    title: 'Asset',
    minWidth: 100,
    align: 'left',
  },
  {
    key: 'amount',
    title: 'Amount',
    minWidth: 150,
    align: 'right',
  },
]

function getOrderHistory(isReset = false) {
  if (isReset) {
    historyList.value = []
  }
  getApiOrderHistory({
    fromId: historyList.value.length ? historyList.value.at(-1)?.id : undefined,
    limit: ORDER_PAGE_SIZE,
    startTime: filter.value.range[0],
    endTime: dayjs(filter.value.range[1]).endOf('day').valueOf(),
    type: filter.value.type?.toUpperCase(),
    assetName: filter.value.assetName?.replace('/', ''),
  }).then((res) => {
    if (isReset) {
      historyList.value = []
    }
    noMore.value = res.data.length < ORDER_PAGE_SIZE
    historyList.value.push(...res.data)
  })
}

function reset() {
  filter.value = {
    type: null,
    range: [dayjs().subtract(6, 'days').valueOf(), dayjs().valueOf()],
    assetName: null,
  }
}

function handleLoad() {
  if (isLoading.value || noMore.value)
    return
  getOrderHistory()
}

watch(filter, () => {
  getOrderHistory(true)
}, {
  deep: true,
})

whenever(isSigned, () => {
  getOrderHistory(true)
}, {
  immediate: true,
})
</script>

<template>
  <div class="relative w-full flex-1 overflow-hidden">
    <div class="flex justify-between" :class="{ 'px-0.28': !historyCurrentSymbol }">
      <div class="ml-0.04 flex items-center gap-x-0.12">
        <n-date-picker
          v-model:value="filter.range"
          start-placeholder="MM/DD/YYYY"
          end-placeholder="MM/DD/YYYY"
          type="daterange"
          size="small"
          format="MM/dd/yyyy"
        >
          <template #date-icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M13.332 2.00033H12.6654V0.666992H11.332V2.00033H4.66536V0.666992H3.33203V2.00033H2.66536C1.93203 2.00033 1.33203 2.60033 1.33203 3.33366V14.0003C1.33203 14.7337 1.93203 15.3337 2.66536 15.3337H13.332C14.0654 15.3337 14.6654 14.7337 14.6654 14.0003V3.33366C14.6654 2.60033 14.0654 2.00033 13.332 2.00033ZM13.332 14.0003H2.66536V6.66699H13.332V14.0003ZM13.332 5.33366H2.66536V3.33366H13.332V5.33366Z" fill="#777E91" />
            </svg>
          </template>
        </n-date-picker>
        <n-select
          v-if="!historyCurrentSymbol" v-model:value="filter.assetName" class="w-1.34" clearable
          :options="assetFilterList" placeholder="Asset"
        />
        <n-select
          v-model:value="filter.type" class="w-1.34" clearable
          :options="typeList.map(i => ({ value: i, label: i }))" placeholder="Type"
        />
        <!-- <div class="cursor-pointer text-0.14 font-500 font-dm text-white2" @click="reset">
          Reset
        </div> -->
      </div>
      <n-checkbox v-if="historyCurrentSymbol" v-model:checked="isOnlyCurrent">
        Current Pair Only
      </n-checkbox>
    </div>
    <n-infinite-scroll style="height: calc(100% - 55px)" :distance="10" class="mt-0.12" @load="handleLoad">
      <v-table
        class="min-h-3 overflow-auto"
        :data="historyList"
        :columns="tableColumns"
        :column-gap="35"
        :loading="isLoading"
        row-key="time"
        content-class="min-h-2.8"
        :row-class="`gap-x-0.16  ${historyCurrentSymbol ? 'py-0.04 h-0.4' : 'py-0.14 h-0.68 border-t-1 border-t-black2 px-0.32'}`"
        :title-row-class="`gap-x-0.16 ${historyCurrentSymbol ? '' : 'py-0.04 px-0.32'}`"
      >
        <template #noData>
          <div>You have no internal transfer history.</div>
        </template>
        <template #time="{ data: { row } }">
          <div>
            <div>{{ dayjs(+row.timestamp).format('MM/DD/YYYY') }}</div>
            <div>{{ dayjs(+row.timestamp).format('HH:mm:ss') }}</div>
          </div>
        </template>
        <template #type="{ data: { row } }">
          <div>{{ capitalizeFirstLetter(row.type) }}</div>
        </template>
        <template #assetName="{ data: { row } }">
          <div class="flex items-center gap-x-0.04">
            <v-icon :currency="row.assetName" class="h-0.24 w-0.24" />
            <div>{{ row.assetName }}</div>
          </div>
        </template>
        <template #address="{ data: { row } }">
          <n-tooltip trigger="hover">
            <template #trigger>
              {{ shortenAddress(row.address) }}
            </template>
            {{ row.address }}
          </n-tooltip>
        </template>
        <template #amount="{ data: { row } }">
          <div :class="row.type === 'SEND' ? 'text-red' : 'text-green'">
            {{ formatNumber(row.amount, -1) }} {{ row.assetName }}
          </div>
        </template>
      </v-table>
    </n-infinite-scroll>
  </div>
</template>

<style scoped>

</style>
