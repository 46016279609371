import { publicHttp, secretHttp } from './http'
import stats from './stats'

export default {
  ...stats,
  getChains: () => publicHttp.get<ChainConfigItem[]>('/chains'),
  getAssetPrice: () => publicHttp.get<AssetPriceItem[]>('/asset/usdPrices'),
  getAllAssetList: () => publicHttp.get<AssetItem[]>('/asset/assets'),
  getAllSymbolList: () => publicHttp.get<SymbolItem[]>('/spot/symbols'),
  getLastTrade: (symbol: string) => publicHttp.get<LastTradeItem[]>(`/spot/lastTrades/${symbol}`),
  getFeeList: () => publicHttp.get<FeeItem[]>('/feeLevels'),
  getKLine: (params: KLineReq) => publicHttp.get<KLineItem[]>('/spot/klines', { params }),
  getUserRegisterChain: (address: string) => publicHttp.get<{ exist: boolean, chainId: string | null }>(`/users/address/${address}/chain`),
  getPoolList: () => publicHttp.get<PoolItem[]>('/amm/pools'),
  getPoolSummaryList: () => publicHttp.get<PoolSummaryResponse>('/amm/poolSummary'),

  createUser: (data: CreateUserRequest) => publicHttp.post<A<CreateUserResponse>>('/users', data),
  getUserInfo: () => secretHttp.get<UserInfo>('/profile'),
  getUserAssets: () => secretHttp.get<UserAsset[]>('/asset/assets'),
  getAdminSignature: (chainId: number) => secretHttp.get<AdminSignature>('/registerSignature', { params: { chainId } }),
  getUserActionHistory: () => secretHttp.get<UserActionHistory[]>('/asset/actionHistory'),
  getInternalHistory: () => secretHttp.get<UserInternalHistory[]>('/asset/internalTransferHistory'),

  // order
  placeOrder: (data: PlaceOrderRequest) => secretHttp.post('/spot/order', data),
  getOpenOrder: () => secretHttp.get<OrderItem[]>('/spot/openOrders'),
  cancelOrder: (params: { symbolName: string, orderId: string }) => secretHttp.delete('/spot/order', { params }),
  cancelAllOrder: (params: { symbolName: string }) => secretHttp.delete('/spot/allOrders', { params }),
  replaceOrder: (data: ReplaceOrderRequest) => secretHttp.post('/spot/order/replace', data),
  getOrderHistory: () => secretHttp.get<OrderItem[]>('/spot/completedOrders'),
  getTradeHistory: () => secretHttp.get<TradeItem[]>('/spot/trades'),
  getAmmActionHistory: () => secretHttp.get<AmmActionHistory[]>('/amm/actionHistory'),

  // spot
  transfer: (data: TransferReq, options: any) => secretHttp.post('/transfer', data, options),
  withdraw: (data: WithdrawReq, options: any) => secretHttp.post('/asset/withdraw', data, options),
  fastWithdraw: (data: FastWithDrawReq) => secretHttp.post('/asset/fastWithdraw', data),
  getFastWithdrawBalance: () => publicHttp.get<FastWithdrawBalanceItem[]>('/asset/fastWithdrawPools'),
  getAvgCost: () => secretHttp.get<AvgCostItem[]>('/asset/avgCost'),
  getBalanceStartOfDay: () => secretHttp.get<StartBalanceItem[]>('/asset/balanceStartOfDay'),
  // getSpotHistory: (params: { periods: number }) => http.get<A<{ history: SpotHistory[] }>>('/gateway/user/balance/history', { params }),

  // faucet
  addMint: (data: AddMintItem) => secretHttp.post('/asset/mint/record', data),
  getMintedList: () => secretHttp.get<MintedItem[]>('/asset/mint/records'),

  // amm
  addPool: (data: AddPoolItem) => secretHttp.post('/amm/addLiquidity', data),
  removeLiquidity: (data: AddPoolItem) => secretHttp.post('/amm/removeLiquidity', data),
  getAmmPosition: () => secretHttp.get<AmmPositionItem[]>('/amm/positions'),
  getPoolLiquidity: (poolId: number) => publicHttp.get<LiquidityRes>(`/amm/poolLiquidity/${poolId}`),
}
