import { useRouteQuery } from '@vueuse/router'

import type { FastWithdrawBalanceItem } from '~/service/modules/public'

export const useSymbol = createGlobalState(() => {
  const currentSymbol = useStorage<string>('currentSymbol', null)
  // const symbolQuery = useRouteQuery('symbol')

  const { assetPriceList } = useData()
  const symbolList = ref<SymbolItem[]>([])
  const assetList = ref<AssetItem[]>([])
  const feeList = ref<FeeItem[]>([])
  const fastWithdrawBalanceList = ref<FastWithdrawBalanceItem[]>([])

  // currencyList tickerInfo  feeInfo fasetWithdrawAssetList fastWithdrawBalanceList currentTickerItem

  async function init() {
    vesselApiServer.getFeeList().then((res) => {
      feeList.value = res.data
    })
    await vesselApiServer.getAllAssetList().then((res) => {
      assetList.value = res.data
    })
    vesselApiServer.getAllSymbolList().then((res) => {
      symbolList.value = res.data
    })
    vesselApiServer.getAssetPrice().then((res) => {
      assetPriceList.value = res.data
    })
  }

  // vesselApi.public.getTickerInfo().then((res) => {
  //   tickerInfo.value = res
  // })
  // vesselApi.public.getFeeInfo().then((res) => {
  //   feeInfo.value = res.data.vipFeeRateDetails
  // })

  getFastWithdrawBalance()
  function getFastWithdrawBalance() {
    vesselApiServer.getFastWithdrawBalance().then((res) => {
      fastWithdrawBalanceList.value = res.data
    })
  }
  init()

  function changeCurrentSymbol(symbol: string) {
    currentSymbol.value = symbol
    // symbolQuery.value = symbol
  }

  return {
    symbolList,
    assetList,
    currentSymbol,
    feeList,
    changeCurrentSymbol,
    // currentTickerItem,
    // tickerInfo,
    // feeInfo,
    fastWithdrawBalanceList,
    getFastWithdrawBalance,
  }
})
